// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Form, Modal, Trans, InputText } from '../..';
import { ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import '../DpsVerifyAddressModal/dps-verify-address-modal.scss';
import {
  states,
  counties,
  statesWithProvinces,
} from '../../../../lib/data/agencyData/DPS';

function DpsAddressTwoMissingModal(props) {
  const {
    onSuccess,
    closeModal,
    id = 'DpsAddressTwoMissingModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions) return false;

  const handleSubmit = values => {
    closeModal(id);
    onSuccess({
      ...data.addressOptions[0],
      state:
        states.find(state => state.value === data.addressOptions[0].state) ||
        statesWithProvinces.find(
          state => state.value === data.addressOptions[0].state
        ),
      county: counties.find(
        county => county.label === (data.addressOptions[0].county || '').toUpperCase()
      ) || { label: 'OTHER', value: '999' },
      addressLine2: values.addressTwoInput,
    });
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="dps-verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="AddressTwoMissingTitle" />
      </h2>
      <div className="modal-content">
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Trans file="Modals" id="AddressTwoMissingBody" />
          </h3>
          <h4>
            {data.addressOptions[0].streetAddress} <br />
            {data.addressOptions[0].city}, {''}
            {data.addressOptions[0].state} {data.addressOptions[0].zipFive}{' '}
            {data.addressOptions[0].county}
          </h4>
        </div>
        <Form
          formikConfig={{
            initialValues: {
              addressTwoInput: '',
            },
            onSubmit: handleSubmit,
          }}
          render={({ FormActions, FormActionsItem, formikProps }) => (
            <>
              <InputText
                className="address-line-2"
                name="addressTwoInput"
                maxLength="32"
                formikProps={formikProps}
                placeholder="Enter an apt, suite, building #, etc."
              />
              <FormActions align="center">
                <FormActionsItem>
                  <ButtonPrimary type="submit">
                    <Trans file="Labels" id="Confirm" />
                  </ButtonPrimary>
                </FormActionsItem>
              </FormActions>
            </>
          )}
        />
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

DpsAddressTwoMissingModal.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DpsAddressTwoMissingModal);
