// Vendors
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// Actions
import { closeModal } from '../../../../reducers/modal/modalActions';

// Components
import { Modal, FormActions, FormActionsItem, Trans } from '../..';
import { Button, ButtonPrimary } from 'texkit/dist/components/Buttons';

// Styles
import '../DpsVerifyAddressModal/dps-verify-address-modal.scss';
import {
  states,
  counties,
  statesWithProvinces,
} from '../../../../lib/data/agencyData/DPS';

function DpsAddressTwoIncorrectModal(props) {
  const {
    onSuccess,
    closeModal,
    id = 'DpsAddressTwoIncorrectModal',
    modal: { data },
    ...rest
  } = props;

  if (!data || !data.addressOptions) return false;

  const handleSuccess = () => {
    closeModal(id);
    onSuccess({
      ...data.addressOptions[0],
      state:
        states.find(state => state.value === data.addressOptions[0].state) ||
        statesWithProvinces.find(
          state => state.value === data.addressOptions[0].state
        ),
      county: counties.find(
        county => county.label === (data.addressOptions[0].county || '').toUpperCase()
      ) || { label: 'OTHER', value: '999' },
    });
  };

  const close = () => {
    closeModal(id);
  };

  return (
    <Modal
      {...rest}
      id={id}
      disableOnClickOutside={true}
      className="verify-address-modal"
    >
      <h2 className="h1 modal-title">
        <Trans file="Modals" id="AddressTwoInvalidTitle" />
      </h2>
      <div className="modal-content">
        <div>
          <h3 style={{ marginTop: 0 }}>
            <Trans file="Modals" id="AddressTwoInvalidBody" />
          </h3>
          <h4>
            {data.addressOptions[0].streetAddress} <br />
            {data.addressOptions[0].addressLine2}
            {data.addressOptions[0].addressLine2.length ? <br /> : ''}
            {data.addressOptions[0].city}, {data.addressOptions[0].state}{' '}
            {data.addressOptions[0].zipFive} {data.addressOptions[0].county}
          </h4>
        </div>
        <FormActions align="center">
          <FormActionsItem>
            <ButtonPrimary onClick={close}>
              <Trans file="Labels" id="Edit" />
            </ButtonPrimary>
          </FormActionsItem>
          <FormActionsItem>
            <Button onClick={handleSuccess}>
              <Trans file="Labels" id="Confirm" />
            </Button>
          </FormActionsItem>
        </FormActions>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal }, dispatch);

DpsAddressTwoIncorrectModal.propTypes = {
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  modal: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DpsAddressTwoIncorrectModal);
