/* eslint-disable flowtype/no-types-missing-file-annotation */
import { DateTime } from 'luxon';
import { push } from 'connected-react-router';

// Helpers
import { to, cloneObject, formatAddressFormForDPS } from '../../../lib/helpers';
import {
  checkRequestFailure,
  handleRequestFailure,
} from '../../../lib/validation';
import { bodyHasError } from '../../../lib/validation/serverErrors';

// Requests
import {
  userVerifyAddressRequest,
  lookupAppLinkRequest,
  donationOptionsRequest,
  submitDLRPayment,
} from '../../../backend/requests';
import { updatedEmergencyContacts } from '../../../backend/dlrRequests';

// Selectors
import {
  selectEmergencyContacts,
  selectDonationOptions,
  selectDonationValues,
  selectOrganDonorOption,
  selectVoterRegistration,
  selectVolunteerElectionJudge,
  selectVoterRegistrationAppType,
  selectSurrenderCardOption,
  selectRenewAddressChangeFlag,
  selectRenewalHomeAddress,
  selectRenewalMailingAddress,
  selectMailingSameAsHomeAddress,
  selectEmergencyContactsFailedStatus,
  selectDlsEmergencyContactsHasData,
  selectJointRenewalUpgradeFlag,
  selectUpgradeFlag,
} from './dlrSelectors';
import { selectAppBasicInfo } from '../../app/appSelectors';
import { selectUserEmail } from '../../user/userSelectors';

// Actions
import { handlePaymentResponse } from '../../payment/paymentActions';
import {
  APP_TRANSACTION_IDS,
  storeBasicInfo,
  clearAppData,
} from '../../app/appActions';
import { openModal } from '../../modal/modalActions';
import { getCentralOffset } from '../../../lib/datetime';

// DPS-DLR Consts
export const DLR_DLRLR_CLEAR_DATA = '[DLR] DLRLR - Clear Data';
export const DLR_REMOVE_ADDRESS_DATA = '[DLR] Remove Address Data';
export const DLR_REMOVE_VOTING_DATA = '[DLR] Remove Voting Data';
export const DLR_CLEAR_COUNTY_DATA = '[DLR] Clear County Data';
export const DLR_RENEW_LICENSE = '[DLR] Renew License';
export const DLR_STORE_APP_PROFILE = '[DLR] Store App Profile';
export const DLR_STORE_FULL_PROFILE = '[DLR] Store Full Profile';
export const DLR_DONATION_OPTIONS = '[DLR] Donation options';
export const DLR_DONATION_VALUES = '[DLR] Donation values';
export const DLR_ORGAN_DONOR_OPTION = '[DLR] Organ Donor options';
export const DLR_VOTER_REGISTRATION = '[DLR] Voter Registration';
export const DLR_VOLUNTEER_ELECTION_JUDGE = '[DLR] Election Judge';
export const DLR_VOTER_REGISTRATION_APPLICATION_TYPE =
  '[DLR] Voter Registration Application Type';
export const DLR_EMERGENCY_CONTACTS = '[DLR] Emergency Contacts';
export const DLR_EMERGENCY_CONTACTS_INITIAL =
  '[DLR] Emergency Contacts Initial';
export const DLR_EMERGENCY_CONTACTS_FAILED_STATUS =
  '[DLR] Emergency Contacts Failed Status';
export const DLR_SURRENDER_CARD = '[DLR] Surrender Card';
export const DLR_PAYMENT_TRANSACT_SUCCESS = '[DLR] Payment Transaction Success';
export const DLR_REPLACE_OR_CHANGE_ADDRESS =
  '[DLR] Replace or Change of Address Flow';
export const DLR_UPDTAE_EMERGENCY_FLAG = '[DLR] update emergency flag';
export const DLR_RENEWAL_FLAG = '[DLR] renewal flag';
export const DLR_MELISA_SUCCESS = 'SUCCESS';
export const DLR_MELISA_CONTINUE = 'CONTINUE';
export const DLR_MELISA_WARNING = 'WARNING';
export const DLR_MELISA_ERROR = 'ERROR';
export const DLR_MELISA_ADDRESS_TWO_MISSING = 'ADDRESS_TWO_MISSING';
export const DLR_MELISA_ADDRESS_TWO_INVALID = 'ADDRESS_TWO_INVALID';
export const DLR_MELISA_ADDRESS_NOT_VERIFIED = 'ADDRESS_NOT_VERIFIED';
export const DLR_MELISA_INVALID_ADDRESS = 'INVALID_ADDRESS';
export const DLR_RENEW_HOME_ADDRESS_UPDATE = 'HOME_ADDRESS';
export const DLR_RENEW_MAILING_ADDRESS_UPDATE = 'MAIL_ADDRESS';
export const DLR_UPDATE_MAILING_ADDRESS_AS_HOME = 'MAILING ADDRESS AS HOME';
export const DLR_IS_ADDRESS_UPDATE = 'ADDRESS UPDATE';
export const DPS_MESSAGE_BANNER_ONLOAD = 'DPS MESSAGE BANNER ONLOAD';
export const DLR_SURRENDER_CARD_PAGE_SELECTION =
  'DLR SURRENDER CARD PAGE SELECTION';
export const DLR_DLS_EMERGENCY_CONTACTS_HAS_DATA =
  '[DLR] DLS service Emergency Contacts Has Data';
export const DLR_UPDATE_PAYMENT_WAS_MADE = 'FALSE';

// DPS-DLU Consts
export const DLR_UPGRADE_FLAG = '[DLR] Upgrade Flag';
export const DLR_JOINT_RENEWAL_UPGRADE_FLAG =
  '[DLR] Joint Renewal Upgrade Flag';

// Renewal DL/ID License
export function renewDLRLicense(data) {
  return async function(dispatch) {
    await dispatch(storeSelected(data));
    await dispatch(storeBasicInfo(data));
    await dispatch(getDLRDonationOptions(APP_TRANSACTION_IDS.RENEWAL));
    await dispatch(storePaymentSuccess(data));
    await dispatch(storeRenewalFlag(true));
    await dispatch(
      storeJointRenewalUpgradeFlag(data.upgradeAndRenewalEligibility && data.dlUpgradePacketStatus === 'Approved')
    );
    dispatch(push('/dpslr'));
  };
}

// Renewal CDL License
export function renewCDLLicense(data) {
  return async function(dispatch) {
    await dispatch(storeSelected(data));
    await dispatch(storeBasicInfo(data));
    await dispatch(getDLRDonationOptions(APP_TRANSACTION_IDS.RENEWAL));
    await dispatch(storePaymentSuccess(data));
    await dispatch(storeRenewalFlag(true));
    dispatch(push('/dpslr/confirmDetails'));
  };
}

export const updatePaymentWasMade = (status = false) => {
  return {
    type: DLR_UPDATE_PAYMENT_WAS_MADE,
    status: status,
  };
};

export const trackerDLR = data => {
  return async function(dispatch) {
    dispatch({
      type: DLR_STORE_APP_PROFILE,
      payload: data,
    });

    dispatch(push('/dlr/dlr-tracker'));
  };
};

export function storeSelected(data) {
  return {
    type: DLR_RENEW_LICENSE,
    payload: data,
  };
}

export function storeHomeAddress(data) {
  return {
    type: DLR_RENEW_HOME_ADDRESS_UPDATE,
    payload: data,
  };
}

export function storeMailingAddress(data) {
  return {
    type: DLR_RENEW_MAILING_ADDRESS_UPDATE,
    payload: data,
  };
}

export function setMailingAddressAsHome(data) {
  return {
    type: DLR_UPDATE_MAILING_ADDRESS_AS_HOME,
    payload: data,
  };
}

export function setIsAddressUpdate(data) {
  return {
    type: DLR_IS_ADDRESS_UPDATE,
    payload: data,
  };
}

export function storeEmergencyContactsInitial(data) {
  return async function(dispatch) {
    dispatch({
      type: DLR_EMERGENCY_CONTACTS_INITIAL,
      payload: data,
    });
  };
}

export function storeEmergencyContacts(data) {
  return async function(dispatch) {
    dispatch({
      type: DLR_EMERGENCY_CONTACTS,
      payload: data,
    });
  };
}

export function storeEmergencyContactsFailedStatus(data) {
  return {
    type: DLR_EMERGENCY_CONTACTS_FAILED_STATUS,
    payload: data,
  };
}

export function storeDlsEmergencyContactsHasData(data) {
  return {
    type: DLR_DLS_EMERGENCY_CONTACTS_HAS_DATA,
    payload: data,
  };
}

export function storeDpsMessageBannerOnLoad(data) {
  return {
    type: DPS_MESSAGE_BANNER_ONLOAD,
    payload: data,
  };
}

export function storeAppProfile(payload) {
  return {
    type: DLR_STORE_APP_PROFILE,
    payload,
  };
}

export function storeSurrenderCardOption(payload) {
  return {
    type: DLR_SURRENDER_CARD,
    payload,
  };
}
export function storeSurrenderCardPageSelection(payload) {
  return {
    type: DLR_SURRENDER_CARD_PAGE_SELECTION,
    payload,
  };
}

export function storeReplaceOrChangeOfdAddress(payload) {
  return {
    type: DLR_REPLACE_OR_CHANGE_ADDRESS,
    payload,
  };
}

export function storeDonationOptions(payload) {
  return {
    type: DLR_DONATION_OPTIONS,
    payload,
  };
}

export function storeDonationValues(payload) {
  return {
    type: DLR_DONATION_VALUES,
    payload,
  };
}

export function storeOrganDonorOption(payload) {
  return {
    type: DLR_ORGAN_DONOR_OPTION,
    payload,
  };
}

export function storeVoterRegistration(payload) {
  return {
    type: DLR_VOTER_REGISTRATION,
    payload,
  };
}

export function storeVolunteerElectionJudge(payload) {
  return {
    type: DLR_VOLUNTEER_ELECTION_JUDGE,
    payload,
  };
}

export function storeVoterRegistrationAppType(payload) {
  return {
    type: DLR_VOTER_REGISTRATION_APPLICATION_TYPE,
    payload,
  };
}
export function storePaymentSuccess(payload) {
  return {
    type: DLR_PAYMENT_TRANSACT_SUCCESS,
    payload,
  };
}

export function storeUpdateEmergencyFlag(payload) {
  return {
    type: DLR_UPDTAE_EMERGENCY_FLAG,
    payload,
  };
}

export function storeRenewalFlag(payload) {
  return {
    type: DLR_RENEWAL_FLAG,
    payload,
  };
}

export function updateEmergencyContactsFromForm(values = {}) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const emergencyContacts = store.dps.emergencyContacts;
    const updatedEmergencyContacts = {
      // Primary Emergency Contact Info
      primaryEmergencyContactInfo:
        !(emergencyContacts.primaryEmergencyContactInfo || {})
          .deleteIndicator ||
        (values.PrimaryFirstName &&
          values.PrimaryFirstName !==
            (emergencyContacts.primaryEmergencyContactInfo || {}).firstName)
          ? {
              ...emergencyContacts.primaryEmergencyContactInfo,
              firstName: values.PrimaryFirstName,
              lastName: values.PrimaryLastName,
              phoneNumber1: values.PrimaryPrimaryPhone
                ? values.PrimaryPrimaryPhone.replace(/-/g, '')
                : undefined,
              phoneNumber2: values.PrimarySecondaryPhone
                ? values.PrimarySecondaryPhone.replace(/-/g, '')
                : undefined,
              phoneExtension1: values.PrimaryExt1,
              phoneExtension2: values.PrimaryExt2,
              street1: values.PrimarystreetAddress,
              state: values.PrimaryFirstName
                ? (values.Primarystate || {}).value
                : '',
              city: values.Primarycity,
              zipCode: values.PrimaryzipFive,
              zipCodeExtension: values.PrimaryzipCodeExtension,
              country: values.PrimaryFirstName
                ? values.Primarycountry.code || values.Primarycountry
                : undefined,
              contactType: !(
                emergencyContacts.primaryEmergencyContactInfo || {}
              ).contactType
                ? 'PRIMARY'
                : (emergencyContacts.primaryEmergencyContactInfo || {})
                    .contactType,
              deleteIndicator:
                !(emergencyContacts.primaryEmergencyContactInfo || {})
                  .deleteIndicator ||
                (values.PrimaryFirstName &&
                  values.PrimaryFirstName !==
                    (emergencyContacts.primaryEmergencyContactInfo || {})
                      .firstName)
                  ? false
                  : (emergencyContacts.primaryEmergencyContactInfo || {})
                      .deleteIndicator,
            }
          : {
              ...emergencyContacts.primaryEmergencyContactInfo,
            },

      // Secondry Emergency Contact Info
      secondryEmergencyContactInfo:
        (!(emergencyContacts.secondryEmergencyContactInfo || {})
          .deleteIndicator &&
          (emergencyContacts.secondryEmergencyContactInfo || {}).contactType ===
            'SECONDARY') ||
        (emergencyContacts.secondryEmergencyContactInfo || {}).contactType ===
          undefined ||
        (values.SecFirstName &&
          values.SecFirstName !==
            (emergencyContacts.secondryEmergencyContactInfo || {}).firstName)
          ? {
              ...emergencyContacts.secondryEmergencyContactInfo,
              firstName: values.SecFirstName,
              lastName: values.SecLastName,
              phoneNumber1: values.SecPrimaryPhone
                ? values.SecPrimaryPhone.replace(/-/g, '')
                : undefined,
              phoneNumber2: values.SecSecondaryPhone
                ? values.SecSecondaryPhone.replace(/-/g, '')
                : undefined,
              phoneExtension1: values.SecExt1,
              phoneExtension2: values.SecExt2,
              street1: values.SecstreetAddress,
              state: values.SecFirstName
                ? (values.Secstate || {}).value
                : undefined,
              city: values.Seccity,
              zipCode: values.SeczipFive,
              zipCodeExtension: values.SeczipCodeExtension,
              country: values.SecFirstName
                ? values.Seccountry.code || values.Seccountry
                : undefined,
              contactType:
                (emergencyContacts.secondryEmergencyContactInfo || {})
                  .contactType && 'SECONDARY',
              deleteIndicator:
                !(emergencyContacts.secondryEmergencyContactInfo || {})
                  .deleteIndicator ||
                (values.SecFirstName &&
                  values.SecFirstName !==
                    (emergencyContacts.secondryEmergencyContactInfo || {})
                      .firstName)
                  ? false
                  : (emergencyContacts.secondryEmergencyContactInfo || {})
                      .deleteIndicator,
            }
          : {
              ...emergencyContacts.secondryEmergencyContactInfo,
            },
    };

    if (!updatedEmergencyContacts.secondryEmergencyContactInfo.firstName) {
      updatedEmergencyContacts.secondryEmergencyContactInfo =
        emergencyContacts.secondryEmergencyContactInfo;
    }
    if (!updatedEmergencyContacts.primaryEmergencyContactInfo.firstName)
      updatedEmergencyContacts.primaryEmergencyContactInfo =
        emergencyContacts.primaryEmergencyContactInfo;

    dispatch(storeEmergencyContacts(updatedEmergencyContacts));
  };
}

export function deleteEmergencyContacts(contactType) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const emergencyContacts = store.dps.emergencyContacts;
    const contactTypeInfo =
      contactType === 'Primary'
        ? 'primaryEmergencyContactInfo'
        : 'secondryEmergencyContactInfo';
    let updatedEmergencyContacts = {};
    if (
      contactType === 'Primary' &&
      emergencyContacts.secondryEmergencyContactInfo &&
      !emergencyContacts.secondryEmergencyContactInfo.deleteIndicator
    ) {
      updatedEmergencyContacts = {
        [contactTypeInfo]: {
          ...emergencyContacts['secondryEmergencyContactInfo'],
          contactType: 'PRIMARY',
        },
        secondryEmergencyContactInfo: {
          ...emergencyContacts['primaryEmergencyContactInfo'],
          deleteIndicator: true,
        },
      };
    } else
      updatedEmergencyContacts = {
        ...emergencyContacts,
        [contactTypeInfo]: {
          ...emergencyContacts[contactTypeInfo],
          deleteIndicator: true,
        },
      };
    dispatch(storeEmergencyContacts(updatedEmergencyContacts));
  };
}

export function getAppProfileByDLRLicense(
  transactionId = APP_TRANSACTION_IDS.RENEWAL_APP_LOOKUP
) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const license = store.dps.selected;
    const { userKey, appId } = license;

    const requestBody = {
      appId,
      userKeys: [userKey],
      transactionId,
      additionalParams: [],
    };

    // if (transactionId === APP_TRANSACTION_IDS.RENEWAL_APP_LOOKUP)
    //   requestBody.additionalParams.push({
    //     name: 'renewalApplicationId',
    //     value: renewalApplicationId,
    //   });
    //returns error
    const [{ body }] = await to(dispatch(lookupAppLinkRequest(requestBody)));
    dispatch(storeAppProfile(body.data));
    // dispatch({
    //   type: DLR_RENEW_LICENSE,
    //   payload: body.data,
    // });
  };
}

// Get Emergency Contacts API call Info
export function getEmergencyContacts(
  transactionId = APP_TRANSACTION_IDS.EMERGENCY_CONTACT_LOOKUP
) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const license = store.dps.selected;
    const { userKey, appId } = license;

    const requestBody = {
      appId,
      userKeys: [userKey],
      transactionId,
      additionalParams: [],
    };

    const [{ body }] = await to(dispatch(lookupAppLinkRequest(requestBody)));

    // if (body && body.errors && body.errors.ERROR.includes('MGMW_EX_3723')) {
    if (body && body.status === 'EXCEPTION') {
      dispatch(storeEmergencyContactsFailedStatus(true));
    } else {
      dispatch(storeEmergencyContactsFailedStatus(false));
      const { profiles = [] } = body.data;
      const account = profiles.length > 0 ? profiles[0].account[0] : {};
      const emergencyContactsPrimary = account.primaryEmergencyContactInfo
        ? {
            primaryEmergencyContactInfo: {
              ...account.primaryEmergencyContactInfo,
              phoneNumber1:
                account.primaryEmergencyContactInfo.phoneNumber1 &&
                account.primaryEmergencyContactInfo.phoneNumber1.replace(
                  /(\d{3})(\d{3})(\d+)/,
                  '$1-$2-$3'
                ),
              phoneNumber2:
                account.primaryEmergencyContactInfo.phoneNumber2 &&
                account.primaryEmergencyContactInfo.phoneNumber2.replace(
                  /(\d{3})(\d{3})(\d+)/,
                  '$1-$2-$3'
                ),
            },
          }
        : undefined;
      const emergencyContactsSecondary = account.secondryEmergencyContactInfo
        ? {
            secondryEmergencyContactInfo: {
              ...account.secondryEmergencyContactInfo,
              phoneNumber1:
                account.secondryEmergencyContactInfo.phoneNumber1 &&
                account.secondryEmergencyContactInfo.phoneNumber1.replace(
                  /(\d{3})(\d{3})(\d+)/,
                  '$1-$2-$3'
                ),
              phoneNumber2:
                account.secondryEmergencyContactInfo.phoneNumber2 &&
                account.secondryEmergencyContactInfo.phoneNumber2.replace(
                  /(\d{3})(\d{3})(\d+)/,
                  '$1-$2-$3'
                ),
            },
          }
        : undefined;

      // Emergency Contacts DLS Service data only on State & Country
      ((account.primaryEmergencyContactInfo &&
        account.primaryEmergencyContactInfo) ||
        (account.secondryEmergencyContactInfo &&
          account.secondryEmergencyContactInfo)) > 0 &&
      (account.primaryEmergencyContactInfo.firstName ||
        account.primaryEmergencyContactInfo.lastName ||
        account.primaryEmergencyContactInfo.phoneNumber1 ||
        account.secondryEmergencyContactInfo.firstName ||
        account.secondryEmergencyContactInfo.lastName ||
        account.secondryEmergencyContactInfo.phoneNumber1)
        ? dispatch(storeDlsEmergencyContactsHasData(false))
        : dispatch(storeDlsEmergencyContactsHasData(true));

      dispatch(
        storeEmergencyContacts({
          ...emergencyContactsPrimary,
          ...emergencyContactsSecondary,
        })
      );
      dispatch(
        storeEmergencyContactsInitial({
          ...emergencyContactsPrimary,
          ...emergencyContactsSecondary,
        })
      );
    }
  };
}

// Emergency Contacts Updating from Form
export const updatedEmergencyContactsInfo = (
  isPrimaryContact = false
) => async (dispatch: Function, getState: Function) => {
  const basicInfo = selectAppBasicInfo(getState());
  const email = selectUserEmail(getState());
  const transactionDateTimeStamp = `${DateTime.local().toFormat(
    'MM/dd/yyyy hh:mm:ss a'
  )} ${getCentralOffset()}`;

  let emergencyContacts = selectEmergencyContacts(getState());
  if (isPrimaryContact) {
    emergencyContacts = {
      primaryEmergencyContactInfo: {
        ...emergencyContacts.secondryEmergencyContactInfo,
        contactType: 'PRIMARY',
      },
      secondryEmergencyContactInfo: {
        ...emergencyContacts.primaryEmergencyContactInfo,
        contactType: 'SECONDARY',
      },
    };
    dispatch(storeEmergencyContacts(emergencyContacts));
  }

  if (
    emergencyContacts.secondryEmergencyContactInfo &&
    emergencyContacts.secondryEmergencyContactInfo.contactType === 'PRIMARY'
  ) {
    emergencyContacts = {
      ...emergencyContacts,
      secondryEmergencyContactInfo: {
        ...emergencyContacts.secondryEmergencyContactInfo,
        contactType: 'SECONDARY',
      },
    };
    dispatch(storeEmergencyContacts(emergencyContacts));
  }
  if (
    emergencyContacts.secondryEmergencyContactInfo &&
    emergencyContacts.secondryEmergencyContactInfo.firstName &&
    !emergencyContacts.secondryEmergencyContactInfo.contactType
  ) {
    emergencyContacts = {
      ...emergencyContacts,
      secondryEmergencyContactInfo: {
        ...emergencyContacts.secondryEmergencyContactInfo,
        contactType: 'SECONDARY',
      },
    };
    dispatch(storeEmergencyContacts(emergencyContacts));
  }
  if (
    emergencyContacts.primaryEmergencyContactInfo &&
    emergencyContacts.primaryEmergencyContactInfo.firstName &&
    !emergencyContacts.primaryEmergencyContactInfo.contactType
  ) {
    emergencyContacts = {
      ...emergencyContacts,
      primaryEmergencyContactInfo: {
        ...emergencyContacts.primaryEmergencyContactInfo,
        contactType: 'PRIMARY',
      },
    };
    dispatch(storeEmergencyContacts(emergencyContacts));
  }
  dispatch(
    storeEmergencyContacts({ ...emergencyContacts, transactionDateTimeStamp })
  );
  const [{ body, response }, err] = await to(
    dispatch(
      updatedEmergencyContacts(
        { ...emergencyContacts, transactionDateTimeStamp },
        basicInfo,
        email
      )
    )
  );

  if (!body || err) {
    return Promise.reject(err);
  }
  if (bodyHasError(body, { errorCodes: ['MGMW_EX_3723'] })) {
    dispatch(storeEmergencyContactsFailedStatus(true));
    return Promise.reject(err);
  }

  return Promise.resolve({ body, response });
};

export function getDLRDonationOptions(serviceType) {
  return async function(dispatch, getStore) {
    const store = getStore();
    const license = store.dps.selected;
    const renewAddressChangeFlag = store.dps.renewAddressChangeFlag;
    const jointRenewalUpgradeFlag = store.dps.jointRenewalUpgradeFlag;
    const upgradeFlag = store.dps.upgradeFlag;
    const { licenseType, veteran, usCitizen } = license;
    const requestBody = {
      licenseType,
      serviceType,
      veteran,
      usCitizen,
    };
    const { renewalFee, upgradeFee, serviceFee, duplicateFee } = license;
    const [{ body }] = await to(dispatch(donationOptionsRequest(requestBody)));
    dispatch(storeDonationOptions(body.data));
    let donationValues = {};
    Object.keys(body.data).forEach(donorOption => {
      if (body.data[donorOption] && donorOption !== 'referenceId') {
        donationValues = { ...donationValues, [donorOption]: 0 };
      }
    });

    // set total to 0 if renewalFee is 0
    let total = 0;
    if (
      !renewAddressChangeFlag &&
      !jointRenewalUpgradeFlag &&
      upgradeFlag &&
      Number.parseInt(upgradeFee) > 0
    ) {
      // Upgrade flow
      total = Number.parseFloat(
        Number.parseInt(upgradeFee) + Number.parseInt(serviceFee)
      ).toFixed(2);
    } else if (!renewAddressChangeFlag && Number.parseInt(renewalFee) > 0) {
      // Renewal flow
      total = Number.parseFloat(
        Number.parseInt(renewalFee) + Number.parseInt(serviceFee)
      ).toFixed(2);
    } else if (renewAddressChangeFlag && Number.parseInt(duplicateFee) > 0) {
      // Replacement flow
      total = Number.parseFloat(
        Number.parseInt(duplicateFee) + Number.parseInt(serviceFee)
      ).toFixed(2);
    } else total = 0;

    donationValues = { ...donationValues, total };
    dispatch(storeDonationValues(donationValues));
  };
}

export const verifyDLRAddress = ({ values, formikActions, addressType }) => {
  return async dispatch => {
    const formValues = cloneObject(values);
    formValues.address = formatAddressFormForDPS(formValues);

    const [{ body, response }, err] = await to(
      dispatch(userVerifyAddressRequest(formValues.address))
    );

    if (err) return Promise.reject(err);

    if (checkRequestFailure({ body, response })) {
      handleRequestFailure(body, formikActions, {
        'address.streetAddress': 'streetAddress',
        'address.addressLine2': 'addressLine2',
        'address.city': 'city',
        'address.state.value': 'state',
        'address.zipFive': 'zipFive',
        'address.county': 'county',
      });

      return Promise.reject();
    }

    if (body.status === DLR_MELISA_CONTINUE) {
      dispatch(
        openModal(`DpsVerifyAddressModal/DPS_${addressType}`, {
          enteredAddress: formValues.address,
          addressOptions: body.data,
        })
      );
    }
    if (
      body.status === DLR_MELISA_WARNING ||
      body.status === DLR_MELISA_ERROR
    ) {
      switch (body.message) {
        case DLR_MELISA_ADDRESS_TWO_MISSING:
          dispatch(
            openModal(`DpsAddressTwoMissingModal/DPS_${addressType}`, {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case DLR_MELISA_ADDRESS_TWO_INVALID:
          dispatch(
            openModal(`DpsAddressTwoIncorrectModal/DPS_${addressType}`, {
              enteredAddress: formValues.address,
              addressOptions: body.data,
            })
          );
          break;
        case DLR_MELISA_ADDRESS_NOT_VERIFIED:
        case DLR_MELISA_INVALID_ADDRESS:
          dispatch(
            openModal(`DpsAddressCantConfirm/DPS_${addressType}`, {
              enteredAddress: formValues.address,
            })
          );
          break;
        default:
          break;
      }
    }
    return Promise.resolve(body);
  };
};

export const submitDLRLRRenewal = () => async (
  dispatch: Function,
  getState: Function
) => {
  const basicInfo = selectAppBasicInfo(getState());
  const donationValue = selectDonationValues(getState());
  const donationOption = selectDonationOptions(getState());
  const organDonorOption = selectOrganDonorOption(getState());
  const voterRegistration = selectVoterRegistration(getState());
  const volunteerElectionJudge = selectVolunteerElectionJudge(getState());
  const voterRegistrationAppType = selectVoterRegistrationAppType(getState());
  const surrenderCardOption = selectSurrenderCardOption(getState());
  const renewAddressChangeFlag = selectRenewAddressChangeFlag(getState());
  const upgradeFlag = selectUpgradeFlag(getState());
  const jointRenewalUpgradeFlag = selectJointRenewalUpgradeFlag(getState());
  const renewalHomeAddress = selectRenewalHomeAddress(getState());
  const renewalMailingAddress = selectRenewalMailingAddress(getState());
  const mailingSameAsHomeAddress = selectMailingSameAsHomeAddress(getState());
  const emergencyContacts = selectEmergencyContacts(getState());
  const emergencyContactsFailedStatus = selectEmergencyContactsFailedStatus(
    getState()
  );
  const isDlsEmergencyContactsHasData = selectDlsEmergencyContactsHasData(
    getState()
  );

  const [{ body, response }, err] = await to(
    dispatch(
      submitDLRPayment(undefined, {
        ...basicInfo,
        donationOption,
        donationValue,
        organDonorOption,
        voterRegistration,
        volunteerElectionJudge,
        voterRegistrationAppType,
        surrenderCardOption,
        renewAddressChangeFlag,
        renewalHomeAddress,
        renewalMailingAddress,
        mailingSameAsHomeAddress,
        emergencyContacts,
        emergencyContactsFailedStatus,
        isDlsEmergencyContactsHasData,
        upgradeFlag,
        jointRenewalUpgradeFlag,
      })
    )
  );

  if (body && body.status !== 'ERROR') {
    dispatch(storePaymentSuccess(body.data));
  }

  return dispatch(handlePaymentResponse({ body, response, err }));
};

export function getRenewAddressSelection() {
  return async function(dispatch) {
    dispatch(storeReplaceOrChangeOfdAddress(true));
  };
}

export const verifyOutOfStateAddress = () => {
  return async function(dispatch) {
    dispatch(openModal('DPS_Out_Of_State_Address_Modal'));
  };
};

export const clearDLRLRData = () => dispatch => {
  dispatch(clearAppData());
  return dispatch({ type: DLR_DLRLR_CLEAR_DATA });
};

export const removeAddressData = () => dispatch =>
  dispatch({ type: DLR_REMOVE_ADDRESS_DATA });

export const removeVotingData = () => dispatch =>
  dispatch({ type: DLR_REMOVE_VOTING_DATA });

export const clearCountyData = () => dispatch =>
  dispatch({ type: DLR_CLEAR_COUNTY_DATA });

// Upgrade DL flow additional functions

export function storeUpgradeFlag(payload) {
  return {
    type: DLR_UPGRADE_FLAG,
    payload,
  };
}
export function storeJointRenewalUpgradeFlag(payload) {
  return {
    type: DLR_JOINT_RENEWAL_UPGRADE_FLAG,
    payload,
  };
}

export function upgradeDLRLicense(data) {
  return async function(dispatch) {
    await dispatch(storeSelected(data));
    await dispatch(storeBasicInfo(data));
    await dispatch(getDLRDonationOptions(APP_TRANSACTION_IDS.UPGRADE));
    await dispatch(storePaymentSuccess(data));
    await dispatch(storeRenewalFlag(data.renewalEligibility));
    await dispatch(
      storeJointRenewalUpgradeFlag(data.upgradeAndRenewalEligibility)
    );
    await dispatch(storeUpgradeFlag(true));
    dispatch(push('/dpslr'));
  };
}
